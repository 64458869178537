import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import { AppleAuthButton, FacebookAuthButton } from '@borrowmydoggy/core-components';

export interface IAuthenticationButtonsProps {
  facebookRedirectPath?: string;
  appleRedirectPath?: string;
}

const ButtonContainer = styled.div`
  display: grid;
  gap: 20px;
  justify-items: stretch;
  padding-top: 20px;
  form { margin: auto; }
  button { width: 300px; }
`;

export const AuthenticationButtons: FunctionComponent<IAuthenticationButtonsProps> = (props: IAuthenticationButtonsProps) => {
  const appleHref = props.appleRedirectPath ? `/auth/apple?redirect_to=${props.appleRedirectPath}` : '/auth/apple';

  let authenticityToken = '';
  const csrfTokenElement = document.querySelector('meta[name=csrf-token]');
  if (csrfTokenElement) {
    const contentAttribute = csrfTokenElement.attributes.getNamedItem('content');
    if (contentAttribute) {
      authenticityToken = contentAttribute.value;
    }
  }

	return (
		<ButtonContainer>
			<form method='post' action={appleHref}>
				<input type='hidden' name='authenticity_token' value={authenticityToken} />
				<AppleAuthButton text='Sign in with Apple' wide />
      </form>
    </ButtonContainer>
  );
};
