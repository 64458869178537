import cn from 'classnames';
import React from 'react';

interface IHeroProps {
	ctaText: string;
	backgroundImage: string;
	backgroundColor: string;
	textColor: string;
}

export const Hero: React.FC<IHeroProps> = ({ ctaText, backgroundImage, textColor }) => {
	return (
		<div className={cn('flex flex-col-reverse bg-bmd-blue md:flex-row')}>
			<div
				className='max-h-[300px] md:max-h-[350px] lg:max-h-[400px] bg-cover bg-center bg-no-repeat'
				style={{
					backgroundImage: `url(${backgroundImage})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundRepeat: 'no-repeat',
					height: '100vh',
					width: '100%'
				}}
			>
				<div className='flex flex-col ml-16 lg:ml-32 xl:ml-[31rem] h-full'>
					<div className='flex gap-8 lg:gap-12 flex-col items-center justify-center w-1/2 xl:w-1/3 h-full'>
						<img src='//d1h75wi75kkzcv.cloudfront.net/images/content/hero/share_the_love.svg' alt='Share the love of dogs' className='w-full' />
						<span className={cn('w-full px-5 py-2 text-2xl md:text-3xl font-lato text-center', textColor)}>{ctaText}</span>
						<a
							href='/signup'
							className={cn(
								'py-4 text-2xl md:text-3xl font-lato text-center text-white w-full bg-[#0EB981] rounded-lg hover:bg-[#0fc78b] transition-colors duration-350',
								textColor
							)}
						>
							Get started
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};
